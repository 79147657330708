html{
    overflow-x: hidden;
}

.App {
    text-align: center;
    font-family: 'Sansita Swashed', cursive;
    
}

/* Materialize Classes Overwrite */

.card{
    margin-bottom: 30px;
}