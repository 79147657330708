.Content{
    margin: 15px;
    padding: 20px 20px 1px 20px;
    border-radius: 1px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.46);
    background-color: rgb(249, 249, 249);
}

.Content .CenterChild {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.Content .CenterChild>div>span {
    font-size: 1.2rem;
    opacity: 0.9;
}
