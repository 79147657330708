.myParallax {
	margin-top: 50px;
    height: 200px;
  }

.myParallax img{
	width: 100%;
}

.fullWidth{
    left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}