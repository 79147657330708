
.TechCardClass:hover, .TechCardClass:active{
    border-bottom: 0 none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
    background-color: rgb(249, 249, 249);
}


.TechCardClass:active{
    background-color: rgb(240, 240, 240)
}