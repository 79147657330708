

/* ROW */
.technologyBox > div{
    
    padding: 5% 0;
   
}


/* img */
.technologyBox  img{
    width: 125px;
}

