
/*============== Card content =================*/



/*===============  Upper part =================*/

.CityCard .UpperPart img{
    width: 100%;
    height: auto;
}

.CityCard .UpperPart span{
     
    font-weight: 700 !important;
    text-shadow: 2px 2px 4px rgba(19,8,5,0.85);
}

/*===============  Lower part =================*/

.CityCard .Content>div:first-child{
    margin: 0 10%;
    position: relative;
    top: 10px;
}

.CityCard .Content>div:first-child canvas{
    width: 100%;
    height: auto;
}

.CityCard .Content .Temp{
    display: inline-block;
     
    font-weight: 700;
    font-size: 4rem;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.753)
}

.CityCard .Content .Desc{
     
    font-weight: 400;
}

.CityCard .Content div.DeleteBtn{
    position: relative;
    
}

.CityCard .Content div.DeleteBtn a{
    position: absolute;
    right: 0;
}

.CityCard .Content div.DeleteBtn a i{
    position: absolute;
    right: 0;

}


/*============== Modal window =================*/

.CityCard .Modal { 
    top: 3vh !important;
    width: 80% !important ; 
    height: 94vh !important ; 
    max-height: 100vh !important ; 
  
} 

.CityCard .Modal>div:first-child{
     overflow: hidden; 
}


.CityCard .Modal .ChartContainer.LineChartContainer{
    width: 80%;
    height: 28vh;
    margin: 0 auto 5vh;
}

.CityCard .Modal .ChartContainer.BarChartContainer{
    width: 78%;
    height: 33vh;
    margin: 0 auto 2vh;
}

.CityCard .Modal h4{
    
    font-weight: 500;
    margin-bottom: 5vh;
}

@media (max-width: 1000px) {

    .CityCard .Modal{
        width: 90% !important ; 
    }

    .CityCard .Modal .ChartContainer {
        width: 100%;
        
    }
}


@media (max-width: 450px) {

    .CityCard .Modal{
        width: 100% !important ; 
        top: 0 !important;
        height: 100vh !important ; 
    }

    .CityCard .Modal>div:first-child{
        overflow: visible; 
   }

    .CityCard .Modal .ChartContainer {
        min-width: 350px;
        min-height: 200px;
    }
}