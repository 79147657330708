.Bar{
    margin-bottom: 30px;
}

.Bar h1{
    
    font-weight: 500;
}

.Bar .Form{
    margin: 0 auto;
    width: 60%;
}

@media (max-width: 600px) {
    .Bar .Form{
        width: 80%;
    }
}

.Bar .Form input{
    text-transform: capitalize;
}

.Bar .Form button{
    display: block;
    margin: 0 auto;
}

.Bar .Sun{
    position: absolute;
    top: -20vw;
    left: -18vw;
}

.Bar .SunAnimation{
    animation: rotate360 2.5s ease-in-out 1;
}

@keyframes rotate360{
    from{ transform: rotate(0deg); }
    to{ transform: rotate(360deg); }
  }

.Bar .Sun img{
    width: 45vw;
    height: auto;
}

#countryData{
    position: relative;
    top: 5px;
}
